<template>
  <div 
    v-if="selectedStatus"
    class="text-center"
  >
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-width="300"
      offset-x
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :color="selectedStatus.color"
          :dark="selectedStatus.dark"
          v-bind="attrs"
          v-on="on"
          block
        >
          {{getTranslation(selectedStatus.name, locale)}}
        </v-btn>
      </template>

      <v-card>
        <v-container>
              <v-row no-gutters>
                <v-col
                  cols="12"
                  xs="6"
                  v-for="(item, index) of status"
                  :key="index"
                  style="padding: 10px;max-width:50%!important;"
                >
                  <v-btn
                    :color="item.color"
                    :dark="item.dark"
                    block
                    @click="handleChange(item.id)"
                  >
                    {{getTranslation(item.name, locale)}}
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  xs="6"
                  style="padding: 10px;max-width:50%!important;"
                >
                  <v-btn
                    block
                    @click="handleClose"
                    text
                  >
                  <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
        </v-container>
      </v-card>
    </v-menu>
  </div>
</template>
<script>
import utils from '@/services/utils'
export default {
  props: {
    value: {
      type: Object,
      required: true,
    },
    onChange: {
      type: Function,
      required: true,
    },
    locale: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    menu: false,
    selectedStatus: null,
  }),
  computed: {
    status () {
      return [
        {
          id: 'new',
          name: { es: 'Nueva', en: 'New' },
          close: false,
          color: 'blue',
          dark: true,
        },
        {
          id: 'pending',
          name: { es: 'Pendiente', en: 'Pending' },
          close: false,
          color: 'pink',
          dark: true,
        },
        {
          id: 'inProgress',
          name: { es: 'En progreso', en: 'In progress' },
          close: false,
          color: 'yellow',
          dark: false,
        },
        {
          id: 'solved',
          name: { es: 'Resuelta', en: 'Solved' },
          close: true,
          color: 'green',
          dark: true,
        },
        {
          id: 'rejected',
          name: { es: 'Rechazada', en: 'Rejected' },
          close: true,
          color: 'red',
          dark: true,
        },
        /*
        {
          id: 'archived',
          name: { es: 'Archivada', en: 'Archived' },
          close: true,
          color: 'red',
        },*/
      ]  
    },
  },
  watch: {
    'value.Status' () {
      this.prepareSelectedStatus()
    },
  },
  mounted () {
    this.prepareSelectedStatus()
  },
  methods: {
    getTranslation (v, locale) {
      return utils.getTranslation(v, locale)
    },
    prepareSelectedStatus () {
      if (!this.value.Status) return
      this.selectedStatus = this.status.filter(x => x.id === this.value.Status).shift()
    },
    handleChange (v) {
      const aux = JSON.parse(JSON.stringify(this.value))
      aux.Status = v
      this.onChange(aux)
      this.selectedStatus = this.status.filter(x => x.id === v).shift()
      this.menu = false
    },
    handleClose () {
      this.menu = false
    },
  },
}
</script>

