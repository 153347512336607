<template>
  <v-card 
    elevation="0"
    style="margin-top:10px;padding: 0 10px;min-height: calc(100vh);"
  >
  <v-row v-if="!editedItem">
    <v-col cols="12">
      <v-btn 
        color="primary"
        outlined
        @click="handleAdd"
        block
      >
        {{ $t('common.add', locale) }}
      </v-btn>
    </v-col>
  </v-row>
  <v-row v-else>
    <v-col
      cols="12"
      xs="12"
    >
      <v-autocomplete
        v-if="managerItems"
        v-model="editedItem.ManagerItemID"
        :items="managerItems"
        filled
        :label="`${$t('dtouch.request.item', locale)}*`"
        item-text="Name"
        item-value="ID"
        outlined
        hide-details
      />
    </v-col>
    <v-col
      v-if="editedItem.Status" 
      cols="12"
      xs="12"
    >
      <small style="color: rgba(0, 0, 0, 0.38);">
        {{$t('dtouch.request.status', locale)}}:
      </small>
      <status-control
        :value="editedItem"
        :onChange="handleChangeStatus"
        :locale="locale"
      />
    </v-col>
    <v-col
      cols="12"
      xs="12"
    >
      <v-text-field
        :label="`${$t('dtouch.request.fullName', locale)}*`"
        v-model="editedItem.FullName"
        required
        outlined
        hide-details
      />
    </v-col>
    <v-col
      cols="12"
      xs="12"
    >
      <v-text-field
        :label="`${$t('dtouch.request.email', locale)}*`"
        v-model="editedItem.Email"
        required
        outlined
        hide-details
      />
    </v-col>
    <v-col
      cols="12"
      xs="12"
    >
      <v-text-field
        :label="`${$t('dtouch.request.room', locale)}*`"
        v-model="editedItem.Room"
        required
        outlined
        hide-details
      />
    </v-col>
    <v-col
      cols="12"
      xs="12"
    >
      <v-textarea
        :label="`${$t('dtouch.request.comment', locale)}`"
        v-model="editedItem.Comment"
        required
        outlined
        hide-details
      />
    </v-col>
    <v-col cols="12">
      <action-buttons
        :id="editedItem.ID"
        :onDeleteItem="handleDelete"
        :onSaveItem="handleSave"
        :onClose="handleClose"
        :locale="locale"
      />
    </v-col>
  </v-row>
  </v-card>
</template>
<script>
const defaultItem = {
  Name: { es: '' },
  Description: { es: '' }
}
import apiDtouch from '@/services/apiDtouch'
import utils from '@/services/utils'
import ActionButtons from '@/components/ActionButtons'
import StatusControl from './StatusControl'
export default {
  components: {
    ActionButtons,
    StatusControl,
  },
  props: {
    workspaceID: {
      type: String,
      required: true,
    },
    editedID : {
      type: String,
      default: null,
    },
    locale: {
      type: String,
      required: true,
    },
    languages: {
      type: Array,
      required: true,
    },
    onClose: {
      type: Function,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    editedItem: null,
    managerItems: null,
  }),
  watch: {
    editedID () {
      this.handleGetItem()
    },
    type () {
      this.handleClose()
      this.init()
    },
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      apiDtouch.getAll (`v2/public/workspaces/${this.workspaceID}/${this.type}-manager`)
        .then(response => {
          if (!response) return
          this.managerItems = response.map(item => {
            item.Name = utils.getTranslation(item.Name, this.locale)
            return item
          })
        })
    },
    handleAdd () {
      this.editedItem = JSON.parse(JSON.stringify(defaultItem))
      this.editedItem.WorkspaceID = this.workspaceID
    },
    handleGetItem () {
      apiDtouch.getItem (`v2/private/${this.type}/${this.editedID}`)
        .then(response => {
          if (!response) return
          const aux = JSON.parse(JSON.stringify(response))

          if (this.type === 'requests') aux.ManagerItemID = aux.RequestsManagerID 
          else aux.ManagerItemID = aux.IssuesManagerID 

          this.editedItem = aux
        })
    },
    handleChangeIsModified () {
      
    },
    handleChangeStatus (v) {
      this.editedItem.Status = v.Status
      this.handleChangeIsModified()
    },
    handleDelete () {
      if (!this.editedItem.ID) return
      apiDtouch.deleteItem(`v2/private/${this.type}/${this.editedItem.ID}`)
        .then(() => {
          this.onClose(true)
          this.handleClose()
        })
      this.handleClose()
    },
    handleSave () {
      if (this.type === 'requests') this.editedItem.RequestsManagerID = this.editedItem.ManagerItemID
      else this.editedItem.IssuesManagerID = this.editedItem.ManagerItemID

      if (!this.editedItem.ID) {
        apiDtouch.addItem(`v2/private/${this.type}/`, this.editedItem)
          .then(response => {
            if (!response) return
            if (response.data.status === 'OK') {
              alert(this.$t('dtouch.request.messages.ok', this.locale))
              this.onClose(true)
              this.handleClose()
            } else alert(this.$t(`dtouch.request.messages.${response.data.message}`, this.locale))
          })
          .catch(() => {
            alert ('ERROR')
          })
      } else {
        apiDtouch.updateItem(`v2/private/${this.type}/${this.editedItem.ID}`, this.editedItem)
          .then(response => {
            if (!response) return
            if (response.data.status === 'OK') {
              alert(this.$t('dtouch.request.messages.okUpdate', this.locale))
              this.onClose(true)
              this.handleClose()
            } else alert(this.$t(`dtouch.request.messages.${response.data.message}`, this.locale))
          })
          .catch(() => {
            alert ('ERROR')
          })
      }
    },
    handleClose () {
      this.editedItem = null
    }
  },
}
</script>

